// LoadPage.js
import React, { Component } from 'react';
import './../../../assets/loader.css';
//spinner.svg
class LoadPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }; 
      }


  render() {
    return (
        <>
            <div id="overlay">
                <table style={{height: "100%",background: "#0D101E"}}>
                    <tbody>
                        <tr>
                        <td className="align-baseline"></td>
                        <td className="align-top"></td>
                        <td className="align-middle"style={{width: "100%"}}><div className="text-center"><img className='loadingpulse img-fluid' style={{maxWidth:"200px"}} src="/images/Cambiavalute_pittogramma_def.svg"/></div></td>
                        <td className="align-bottom"></td>
                        <td className="align-text-top"></td>
                        <td className="align-text-bottom"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </>
    );
  }
}

export default LoadPage;