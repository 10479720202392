import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
//import Footer from './Components/CommonComponents/NavigationComponent/Footer';
//import LoginPage from './Components/AppComponents/LoginComponents/Login';
//import ChooseTypePage from './Components/AppComponents/RegistrationComponents/ChooseType';
//import CustomerInsertionForm from './Components/AppComponents/RegistrationComponents/Customers/CustomerUserInsertionForm';
//import CompanyInsertionFormPage from './Components/AppComponents/RegistrationComponents/Companies/CompanyInsertionForm';
//import PrivateRoute from './Components/PrivateRoute';
//import DashboardHome from './Components/AppComponents/CustomerComponents/DashboardHome';
//import DocumentsUploadPage from './Components/CommonComponents/DocumentsComponents/DocumentsUploadPage';
//import MenuHeader from './Components/CommonComponents/MenuComponent/MenuComponent';
//import BankAccountsPage from './Components/CommonComponents/BankAccountsComponents/BankAccountsPage';
//import ProfilePage from './Components/CommonComponents/ProfileComponents/ProfilePage';
//import UserSettingsPage from './Components/CommonComponents/UserSettingsComponents/UserSettingsComponent';
//import OrderPageComponent from './Components/CommonComponents/OrderPageComponent/OrderPageComponent';
//import RecoverPasswordPage from './Components/CommonComponents/ResetPasswordComponent/RecoverPasswordComponent';
//import ResetPasswordPage from './Components/CommonComponents/ResetPasswordComponent/ResetPasswordComponent';
//import AdminMenu from './Components/AppComponents/AdminComponents/AdminMenu/AdminMenu';
//import AdminOrders from './Components/AppComponents/AdminComponents/AdminOrders';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
//import AdminCustomers from './Components/AppComponents/AdminComponents/AdminCustomers';
//import AdminCustomerDetail from './Components/AppComponents/AdminComponents/AdminCustomerDetail';
//import AdminCompanies from './Components/AppComponents/AdminComponents/AdminCompanies';
//import AdminDashboard from './Components/AppComponents/AdminComponents/AdminDashboard';
//import AdminCompanyDetail from './Components/AppComponents/AdminComponents/AdminCompanyDetail';
//import AdminSettings from './Components/AppComponents/AdminComponents/AdminSettings';
//import NotFoundPage from './Components/CommonComponents/BaseComponents/NotFoundPage';
//import AdminFooter from './Components/AppComponents/AdminComponents/AdminMenu/AdminFooter';
//import AdminOrderDetail from './Components/AppComponents/AdminComponents/AdminOrderDetail';
//import AdminOrderNew from './Components/AppComponents/AdminComponents/AdminOrderNew';
//import AdminExport from './Components/AppComponents/AdminComponents/AdminExport';
//import UserOrderDetailPage from './Components/CommonComponents/OrderDetail/OrderDetailPage';
//import AlertSettings from './Components/CommonComponents/Alerts/AlertSettings';
//import AutomaticOrderComponent from './Components/CommonComponents/AutomaticOrderComponent/AutomaticOrderComponent';
//import PrivateRouteGoToOrder from './Components/PrivateRouteGoToOrder';
//import BaseRedirect from './Components/CommonComponents/BaseComponents/BaseRedirect';
import LoadPage from './Components/CommonComponents/BaseComponents/loadpage';

const BaseRedirect = lazy(() => import( /* webpackChunkName: "BaseRedirectComponents" */'./Components/CommonComponents/BaseComponents/BaseRedirect'));
const PrivateRouteGoToOrder = lazy(() => import(   /* webpackChunkName: "PrivateRouteGoToOrderComponents" */'./Components/PrivateRouteGoToOrder'));
const AutomaticOrderComponent = lazy(() => import( /* webpackChunkName: "AutomaticOrderComponentComponents" */  './Components/CommonComponents/AutomaticOrderComponent/AutomaticOrderComponent'));
const AlertSettings = lazy(() => import(/* webpackChunkName: "AlertSettingsComponents" */'./Components/CommonComponents/Alerts/AlertSettings'));
const UserOrderDetailPage = lazy(() => import(/* webpackChunkName: "UserOrderDetailPageComponents" */'./Components/CommonComponents/OrderDetail/OrderDetailPage'));
const AdminOrders = lazy(() => import(/* webpackChunkName: "AdminOrdersComponents" */'./Components/AppComponents/AdminComponents/AdminOrders'));
const AdminBonuses = lazy(() => import(/* webpackChunkName: "AdminBonusesComponents" */'./Components/AppComponents/AdminComponents/AdminBonuses'));
const AdminSettings = lazy(() => import(/* webpackChunkName: "AdminSettingsComponents" */'./Components/AppComponents/AdminComponents/AdminSettings'));
const AdminExport = lazy(() => import(/* webpackChunkName: "AdminExportComponents" */'./Components/AppComponents/AdminComponents/AdminExport'));
const AdminOrderNew = lazy(() => import(/* webpackChunkName: "AdminOrderNewComponents" */'./Components/AppComponents/AdminComponents/AdminOrderNew'));
const AdminBonusNew = lazy(() => import(/* webpackChunkName: "AdminBonusNewComponents" */'./Components/AppComponents/AdminComponents/AdminBonusNew'));
const AdminOrderDetail = lazy(() => import( /* webpackChunkName: "AdminOrderDetailComponents" */'./Components/AppComponents/AdminComponents/AdminOrderDetail'));
const AdminBonusDetail = lazy(() => import( /* webpackChunkName: "AdminBonusDetailComponents" */'./Components/AppComponents/AdminComponents/AdminBonusDetail'));
const AdminFooter = lazy(() => import( /* webpackChunkName: "AdminFooterComponents" */'./Components/AppComponents/AdminComponents/AdminMenu/AdminFooter'));
const NotFoundPage = lazy(() => import(/* webpackChunkName: "NotFoundPageComponents" */'./Components/CommonComponents/BaseComponents/NotFoundPage'));
const RecoverPasswordPage = lazy(() => import(/* webpackChunkName: "RecoverPasswordPageComponents" */'./Components/CommonComponents/ResetPasswordComponent/RecoverPasswordComponent'));
const AdminCompanyDetail = lazy(() => import(/* webpackChunkName: "AdminCompanyDetailComponents" */'./Components/AppComponents/AdminComponents/AdminCompanyDetail'));
const AdminDashboard = lazy(() => import( /* webpackChunkName: "AdminDashboardComponents" */  './Components/AppComponents/AdminComponents/AdminDashboard'));
const AdminDashboardUsers = lazy(() => import( /* webpackChunkName: "AdminDashboardUsers" */  './Components/AppComponents/AdminComponents/AdminDashboardUsers'));
const DashboardUserTablesComponent = lazy(() => import( /* webpackChunkName: "DashboardUserTablesComponent" */  './Components/AppComponents/AdminComponents/DashboardComponent/DashboardUserTablesComponent'));
const AdminCompanies = lazy(() => import( /* webpackChunkName: "AdminCompaniesComponents" */  './Components/AppComponents/AdminComponents/AdminCompanies'));
const AdminCustomerDetail = lazy(() => import(/* webpackChunkName: "AdminCustomerDetailComponents" */'./Components/AppComponents/AdminComponents/AdminCustomerDetail'));
const AdminCustomers = lazy(() => import(/* webpackChunkName: "AdminCustomersComponents" */'./Components/AppComponents/AdminComponents/AdminCustomers'));
const AdminMenu = lazy(() => import(/* webpackChunkName: "AdminMenuComponents" */'./Components/AppComponents/AdminComponents/AdminMenu/AdminMenu'));
const ResetPasswordPage = lazy(() => import(/* webpackChunkName: "ResetPasswordPageComponents" */'./Components/CommonComponents/ResetPasswordComponent/ResetPasswordComponent'));
const OrderPageComponent = lazy(() => import( /* webpackChunkName: "OrderPageComponentComponents" */'./Components/CommonComponents/OrderPageComponent/OrderPageComponent'));
const UserSettingsPage = lazy(() => import(/* webpackChunkName: "UserSettingsPageComponents" */'./Components/CommonComponents/UserSettingsComponents/UserSettingsComponent'));
const ProfilePage = lazy(() => import(/* webpackChunkName: "ProfilePageComponents" */ './Components/CommonComponents/ProfileComponents/ProfilePage'));
const BankAccountsPage = lazy(() => import(/* webpackChunkName: "BankAccountsPageComponents" */ './Components/CommonComponents/BankAccountsComponents/BankAccountsPage'));
const MenuHeader = lazy(() => import(/* webpackChunkName: "MenuHeaderComponents" */'./Components/CommonComponents/MenuComponent/MenuComponent'));
const DocumentsUploadPage = lazy(() => import( /* webpackChunkName: "DocumentsUploadPageComponents" */'./Components/CommonComponents/DocumentsComponents/DocumentsUploadPage'));
const DashboardHome = lazy(() => import(/* webpackChunkName: "DashboardHomeComponents" */'./Components/AppComponents/CustomerComponents/DashboardHome'));
const Wrapped = lazy(() => import(/* webpackChunkName: "WrappedComponents" */'./Components/AppComponents/CustomerComponents/Wrapped'));
const PrivateRoute = lazy(() => import( /* webpackChunkName: "PrivateRouteComponents" */  './Components/PrivateRoute'));
const CompanyInsertionFormPage = lazy(() => import( /* webpackChunkName: "CompanyInsertionFormPageComponents" */'./Components/AppComponents/RegistrationComponents/Companies/CompanyInsertionForm'));
const CustomerInsertionForm = lazy(() => import( /* webpackChunkName: "CustomerInsertionFormComponents" */'./Components/AppComponents/RegistrationComponents/Customers/CustomerUserInsertionForm'));
const ChooseTypePage = lazy(() => import( /* webpackChunkName: "ChooseTypePageComponents" */'./Components/AppComponents/RegistrationComponents/ChooseType'));
const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPageComponents" */'./Components/AppComponents/LoginComponents/Login'));
const Footer = lazy(() => import( /* webpackChunkName: "FooterComponents" */  './Components/CommonComponents/NavigationComponent/Footer'));
const UserReferralStats = lazy(() => import( /* webpackChunkName: "UserReferralStats" */  './Components/CommonComponents/ReferralComponents/UserReferralStats'));
const history = createBrowserHistory();
Sentry.init({
  integrations: [
    new Integrations.BrowserTracing({
      // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadPage />}>
        <Switch>
          <Route exact path="/" render={props =>
            <BaseRedirect />
          } />
          <Route exact path="/:lan/" render={props =>
            <LoginPage />
          } />
          <Route exact path="/:lan/login" render={props =>
            <LoginPage />
          } />
          <Route exact path="/login" render={props =>
            <LoginPage />
          } />
          <Route exact path="/:lan/activate-user/:token" render={props =>
            <LoginPage />
          } />
          {/*<Route exact path="/:lan/signup" render={props => 
          <RegistrationPage/>
      } />*/}
          <Route exact path="/:lan/signin" render={props =>
            <LoginPage />
          } />
          <Route exact path="/:lan/recover-password" render={props =>
            <RecoverPasswordPage />
          } />
          <Route exact path="/:lan/reset-password/:token" render={props =>
            <ResetPasswordPage />
          } />
          <Route exact path="/:lan/signin/:cashinput/:currencyinput/:currencyoutput" render={props =>
            <LoginPage />
          } />
          <Route exact path="/:lan/signin/stats" render={props =>
            <LoginPage />
          } />
          <Route exact path="/:lan/request-order/:cashinput/:currencyinput/:currencyoutput" render={props =>
            <><MenuHeader /> <OrderPageComponent /><Footer /></>
          } />
          <Route exact path="/:lan/registration/" render={props =>
            <><ChooseTypePage />
              <Footer /></>
          } />
          <Route exact path="/:lan/private/register" render={props =>
            <><CustomerInsertionForm />
              <Footer /></>
          } />
          <Route exact path="/:lan/private/signup/:invitercode" render={props =>
            <><CustomerInsertionForm />
              <Footer /></>
          } />
          <Route exact path="/:lan/company/register" render={props =>
            <><CompanyInsertionFormPage />
              <Footer /></>
          } />
          <PrivateRoute exact path="/:lan/in" render={<><MenuHeader /> <DashboardHome /><Footer /></>} />
          <PrivateRoute exact path="/:lan/in/stats" render={<><MenuHeader /> <Wrapped /><Footer /></>} />
          <PrivateRoute exact path="/:lan/in/orders/detail/:id" render={<><MenuHeader /><UserOrderDetailPage /><Footer /></>} />
          <PrivateRoute exact path="/:lan/in/docs" render={<><MenuHeader /><DocumentsUploadPage /><Footer /></>} />
          <PrivateRoute exact path="/:lan/in/bankdata" render={<><MenuHeader /><BankAccountsPage /><Footer /></>} />
          <PrivateRoute exact path="/:lan/in/profile" render={<><MenuHeader /><ProfilePage /><Footer /></>} />
          <PrivateRoute exact path="/:lan/in/settings" render={<><MenuHeader /><UserSettingsPage /><Footer /></>} />
          <PrivateRoute exact path="/:lan/in/alert-settings" render={<><MenuHeader /><AlertSettings /> <Footer /></>} />
          <PrivateRoute exact path="/:lan/in/invite" render={<><MenuHeader /><UserReferralStats /><Footer /></>} />
          <PrivateRouteGoToOrder exact path="/:lan/in/alerts" />
          <PrivateRoute path="/:lan/in/automatic-orders" render={<><MenuHeader /><AutomaticOrderComponent /><Footer /></>} />
          {/*<PrivateRoute exact path="/:lan/in/whatsapp-alert" render={<><MenuHeader/><WhatsappAlert/><Footer/></>} />*/}
          {/*ADMIN */}
          <PrivateRoute exact path="/admin/dashboard" render={<><AdminMenu /><AdminDashboard /><AdminFooter /></>} />
          <PrivateRoute exact path="/admin/stats/users" render={<><AdminMenu /><AdminDashboardUsers /><AdminFooter /> </>} />
          <PrivateRoute exact path="/admin/stats/users/tables" render={<><AdminMenu /><DashboardUserTablesComponent /> <AdminFooter /> </>} />
          <PrivateRoute exact path="/admin/customers" render={<><AdminMenu /><AdminCustomers /><AdminFooter /> </>} />
          <PrivateRoute exact path="/admin/companies" render={<><AdminMenu /><AdminCompanies /><AdminFooter /> </>} />
          <PrivateRoute exact path="/admin/customers/detail/:id" render={<><AdminMenu /><AdminCustomerDetail /> <AdminFooter /></>} />
          <PrivateRoute exact path="/admin/companies/detail/:id" render={<><AdminMenu /><AdminCompanyDetail /><AdminFooter /> </>} />
          <PrivateRoute exact path="/admin/orders/:status" render={<><AdminMenu /><AdminOrders /><AdminFooter />  </>} />
          <PrivateRoute exact path="/admin/bonuses/:status" render={<><AdminMenu /><AdminBonuses /><AdminFooter />  </>} />
          <PrivateRoute exact path="/admin/bonuses/detail/:id" render={<><AdminMenu /><AdminBonusDetail /><AdminFooter />  </>} />
          <PrivateRoute exact path="/admin/orders/detail/:id" render={<><AdminMenu /><AdminOrderDetail /><AdminFooter />  </>} />
          <PrivateRoute exact path="/admin/orders/new/:id" render={<><AdminMenu /><AdminOrderNew /><AdminFooter />  </>} />
          <PrivateRoute exact path="/admin/bonus/new/:id" render={<><AdminMenu /><AdminBonusNew /><AdminFooter />  </>} />
          <PrivateRoute exact path="/admin/orders/new" render={<><AdminMenu /><AdminOrderNew /><AdminFooter />  </>} />
          <PrivateRoute exact path="/admin/export" render={<><AdminMenu /><AdminExport /><AdminFooter /></>} />
          <PrivateRoute exact path="/admin/settings" render={<><AdminMenu /><AdminSettings /><AdminFooter /> </>} />
          <Route path="*" >
            <><NotFoundPage /><Footer /></>
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
